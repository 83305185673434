<template>
  <div class="foot_warp">
    <div class="foot_content_warp">
      <div class="font_left_warp">
        <div class="font_left_content_warp">
          <div class="content_item_warp">
            <!--            關於我們-->
            <div class="title" v-on:click="jumpAbout('intro', 'corporateCulture')">
              {{ this.$t("home.foot.aboutUs.title") }}
            </div>
            <!--            企业文化-->
            <div
              class="item_warp"
              v-on:click="jumpAbout('intro', 'corporateCulture')"
            >
              {{ this.$t("home.foot.aboutUs.corporateCulture") }}
            </div>
            <!--            组织架构-->
            <div
              class="item_warp"
              v-on:click="jumpAbout('intro', 'organizationalStructure')"
            >
              {{ this.$t("home.foot.aboutUs.organizationalStructure") }}
            </div>
            <!--            资质荣誉-->
            <div class="item_warp" v-on:click="jumpAbout('intro', 'aptitude')">
              {{ this.$t("home.foot.aboutUs.qualificationHonor") }}
            </div>
            <!--            新闻资讯-->
            <div class="item_warp" v-on:click="jumpAbout('news')">
              {{ this.$t("home.foot.aboutUs.newsInformation") }}
            </div>
            <!--            网站申明-->
            <div class="item_warp">
              {{ this.$t("home.foot.aboutUs.websiteStatement") }}
            </div>
            <!--            站点地图-->
            <div class="item_warp">
              {{ this.$t("home.foot.aboutUs.siteMap") }}
            </div>
          </div>
          <div class="content_item_warp">
            <!--            集团业务-->
            <div class="title">
              {{ this.$t("home.foot.groupBusiness.title") }}
            </div>
            <!--            艺术品质押-->
            <div class="item_warp">
              {{ this.$t("home.foot.groupBusiness.artPledge") }}
            </div>
            <!--            艺术品典当-->
            <div class="item_warp">
              {{ this.$t("home.foot.groupBusiness.artPawning") }}
            </div>
            <!--            艺术品信托-->
            <div class="item_warp">
              {{ this.$t("home.foot.groupBusiness.artTrust") }}
            </div>
            <!--            艺术品保险-->
            <div class="item_warp">
              {{ this.$t("home.foot.groupBusiness.artInsurance") }}
            </div>
            <!--            艺术证券化-->
            <div class="item_warp">
              {{ this.$t("home.foot.groupBusiness.artSecuritization") }}
            </div>
            <!--            艺术产权交易-->
            <div class="item_warp">
              {{ this.$t("home.foot.groupBusiness.equityTransaction") }}
            </div>
          </div>
        </div>
      </div>
      <div class="font_right_warp">
        <div class="content_item_warp">
          <!--          投资者关系-->
          <div class="title">
            {{ this.$t("home.foot.investorRelations.title") }}
          </div>
          <!--          信息披露-->
          <div class="item_warp">
            {{ this.$t("home.foot.investorRelations.informationDisclosure") }}
          </div>
          <!--          业绩报告-->
          <div class="item_warp">
            {{ this.$t("home.foot.investorRelations.performanceReport") }}
          </div>
          <!--          社会责任-->
          <div class="item_warp">
            {{ this.$t("home.foot.investorRelations.socialResponsibility") }}
          </div>
          <!--          境外分行-->
          <div class="item_warp">
            {{ this.$t("home.foot.investorRelations.OverseasBranch") }}
          </div>
          <!--          条款细则-->
          <div class="item_warp">
            {{ this.$t("home.foot.investorRelations.clause") }}
          </div>
          <!--          加入我们-->
          <div class="item_warp">
            {{ this.$t("home.foot.investorRelations.joinUs") }}
          </div>
        </div>
        <div class="content_item_warp">
          <!--          联系我们-->
          <div class="title" v-on:click="jumpContactUs">
            {{ this.$t("home.foot.contactUs.title") }}
          </div>
          <!--          服务热线-->
          <div class="item_warp">
            {{ this.$t("home.foot.contactUs.serviceHotline") }}：+86 18710205053
          </div>
          <!--          投诉建议-->
          <div class="item_warp">
            {{ this.$t("home.foot.contactUs.suggestion") }}：art_bank@126.com
          </div>
          <!--          工作时间-->
          <div class="item_warp">
            {{ this.$t("home.foot.contactUs.workingHours") }}：9：00 - 21：00
          </div>
          <!--          邮政编码-->
          <div class="item_warp">
            {{ this.$t("home.foot.contactUs.postalCode") }}：518000
          </div>
          <!--          公司地址 北京-朝阳-高碑店-国粹苑-->
          <div class="item_warp">
            {{ this.$t("home.foot.contactUs.companyAddress") }}：{{
              this.$t("home.foot.contactUs.companyAddressDes")
            }}
          </div>
          <div class="item_warp">
            <el-select
              v-model="friendlyLink"
              placeholder="友情链接"
              class="friendly_link_warp"
              @change="friendlyLinkChange"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in friendlyOption"
                :key="item.key"
                :label="item.title"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="font_left_but_warp">
      <div class="font_lang_warp">
        <div class="font_left_but_line" />
      </div>
      <div class="font_record_warp">
        Copyright © 2017-2024 {{ this.$t("projectTitle") }} All Rights
        Reserved.<br />
        <a
          href="https://beian.miit.gov.cn/"
          target="blank"
          style="cursor: pointer;font-size: 0.7rem;"
          >备案号：粤ICP备17128723号</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { constantRouterMap } from "@/router";

export default {
  name: "Foot",
  components: {},
  data() {
    return {
      friendlyLink: "", // 友情链接,
      friendlyOption: [
        {
          key: "art_bank",
          title: "中国艺术银行",
          hrefPath: "https://chinaartbank.org"
        },
        {
          key: "cnaf",
          title: "国家艺术基金",
          hrefPath: "https://www.cnaf.cn"
        },
        {
          key: "ncha",
          title: "国家文物局",
          hrefPath: "http://www.ncha.gov.cn"
        },
        {
          key: "china_cflac",
          title: "中国文艺网",
          hrefPath: "https://www.cflac.org.cn"
        },
        {
          key: "china_art",
          title: "艺术中国",
          hrefPath: "http://art.china.cn"
        },
        {
          key: "china_namoc",
          title: "中国美术馆",
          hrefPath: "https://www.namoc.org"
        },
        {
          key: "china_caanet",
          title: "中国美术家协会",
          hrefPath: "https://www.caanet.org.cn"
        },
        {
          key: "china_zgysyjy",
          title: "中国艺术研究院",
          hrefPath: "https://www.zgysyjy.org.cn"
        },
        {
          key: "china_tdzqg",
          title: "天地正气集团",
          hrefPath: "http://tdzqg.art-bank.net"
        },
        {
          key: "yidot",
          title: "壹点科技官方",
          hrefPath: "https://www.yidot.com"
        }
      ]
    };
  },
  computed: {},
  mounted() {},
  created() {
    if (constantRouterMap) {
      this.routerList = constantRouterMap[0].children;
    }
    const currentPath = this.$route.path;
    if (currentPath) {
      this.selectedKeys = currentPath.replace("/", "");
    }
  },
  methods: {
    /**
     * 跳转到联系我们
     */
    jumpContactUs() {
      this.$store.commit("SET_SELECTEDKEYS", "contactUs");
      this.$router.push({ path: "/contactUs" });
    },
    /**
     * 关于我们
     */
    jumpAbout(type, tabKey) {
      this.$store.commit("SET_SELECTEDKEYS", "newsCenter");
      let path = "/newsCenter?type=" + type;
      if (tabKey) {
        path += "&tabKey=" + tabKey;
      }
      this.$router.push({ path });
    },
    /**
     * 友情链接
     */
    friendlyLinkChange(item) {
      let href = "";
      const friendlyOption = this.friendlyOption;
      friendlyOption.map(o => {
        if (o.key === item) {
          href = o.hrefPath;
        }
      });
      if (href) {
        window.open(href, "_blank");
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../styles/variables.scss";
@import "../../../styles/index.scss";

.foot_warp {
  width: 100%;
  min-width: $min-width;
  background: url("../../../assets/home/bg/foot.png") no-repeat;
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
  color: #ffffff;
  padding: 18px 10%;
  font-family: SiYuanSongTiRegular;
  .foot_content_warp {
    display: flex;
    justify-content: space-between;
    .font_left_warp {
      width: 50%;
      .font_left_content_warp {
        display: flex;
        justify-content: space-around;
        width: 100%;
      }
    }
    .font_right_warp {
      width: 50%;
      display: flex;
      justify-content: space-around;
    }
  }
  .font_left_but_warp {
    padding-left: 100px;
    .font_left_but_line {
      border-top: 1px solid #ffffff;
      width: 100%;
      margin: 14px 20px 0 0;
    }
    .font_lang_warp {
      display: flex;
      margin: 10px 0;
    }
    .font_record_warp {
      font-size: 0.8rem;
      line-height: 24px;
      text-align: center;
      margin-top: 10px;
    }
  }
  .content_item_warp {
    line-height: 28px;
    font-size: 0.8rem;
    //margin-left: 70px;
    cursor: pointer;

    .title {
      font-size: 1rem;
      margin-bottom: 6px;
      font-weight: 600;
    }
    .title:hover {
      font-size: 1.1rem;
      transform: scale(1);
      transition: all 1s;
    }
    .item_warp:hover {
      font-size: 0.9rem;
      transform: scale(1);
      transition: all 1s;
    }
  }
}
.friendly_link_warp {
  /deep/ {
    .el-input--suffix .el-input__inner {
      background-color: $theme-red;
      border: 0px;
      color: #fefdfb !important;
      box-shadow: inset 0 0 10px 0px #9d1913;
      border-radius: 8px;
    }
    .el-select .el-input .el-select__caret {
      color: #ffffff !important;
    }
    .selected {
      color: $theme-red !important;
    }
    .el-select__caret {
      color: #fefdfb !important;
    }
  }
}
</style>
