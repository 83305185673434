export default {
  projectTitle: "中國藝術銀行",
  // 路由
  route: {
    home: "首頁",
    learnMore: "詳情",
    artist: "藝術家",
    artisticActivity: "藝術活動",
    artwork: "藝術品",
    financialService: "金融服務",
    hotelClub: "酒店俱樂部",
    supportSystem: "支撐體系",
    tradingSystem: "交易體系",
    legalSystem: "法務體系",
    trainSystem: "培訓體系",
    serviceSystem: "服務體系",
    marketingSystem: "營銷體系",
    financialSystem: "金融體系",
    supplyChainSystem: "供應鏈體系",
    sortingSystem: "清分體系",
    moisteningSystem: "分潤體系",
    operationSystem: "營運體系",
    integratedSystem: "整合體系",
    backdoorSystem: "借殼體系",
    incentiveSystem: "激勵體系",
    memberCenter: "會員中心",
    contactUs: "聯係我們",
    newsCenter: "新聞中心",
    beijing: "北京分行",
    shanghai: "上海分行",
    guangzhou: "廣州分行",
    shenzhen: "深圳分行",
    hongkong: "香港分行",
    macau: "澳門总部",
    newyork: "紐約分行",
    sanFrancisco: "舊金山分行",
    singapore: "新加坡分行",
    tokyo: "東京分行",
    create: "藝術創作",
    behavior: "藝術行爲",
    collect: "藝術收藏",
    oilPainting: "油畫水粉",
    ly1: "劉宇壹",
    inkCalligraphy: "水墨書法",
    sketchPrint: "素描版畫",
    holding: "館藏作品",
    music: "音樂",
    popMusic: "流行音樂",
    jazzMusic: "爵士樂",
    classicalMusic: "古典樂",
    dance: "舞蹈",
    classicalDance: "古典舞",
    folkDance: "民族舞",
    modernDance: "現代舞",
    drama: "戲劇",
    opera: "歌劇",
    danceDrama: "舞劇",
    puppetShow: "木偶戲",
    shadowPlay: "皮影戲",
    quyi: "曲藝",
    brand: "牌子曲類",
    balladCi: "彈詞類",
    drumsMelodies: "鼓曲類",
    luteCalligraphy: "琴書類",
    dogen: "道琴類",
    personage: "個人收藏",
    folk: "民間收藏",
    theme: "主題收藏",
    artGallery: "藝術館收藏",
    insurance: "金融保險",
    artPackage: "藝術資產包",
    pingan: "平安保險",
    taiping: "太平保險",
    lease: "金融租賃",
    factor: "金融保理",
    service: "金融服務",
    social: "社會活動", // 社会活动
    exhibitionTour: "參展巡展",
    office: "任職任教",
    majorTheme: "重大题材",
    acceptStudents: "收徒",
    exhibition: "辦展",
    gift: "饋贈",
    associationPen: "筆會",
    internationalExhibitionTour: "國際巡展",
    beltRoad: "壹帶壹路",
    stateAuction: "國家拍賣",
    importantCollection: "重要收藏",
    internationalLecture: "國際講學",
    autonomous: "自主活動", // 自主活动
    internationalExchange: "國際交流", // 国际交流
    collectible: "收藏品", //
    sort: "分類",
    all: "全部",
    calligraphyInscriptions: "書法題字",
    chinese: "水墨國畫",
    figureOil: "人物油畫",
    stoneRhyme: "石韻畫",
    world: "世界系列",
    author: "作者",
    dimension: "尺寸",
    price: "價格",
    appraisalCenter: "鑒定中心",
    wenChiao: "文交系統",
    south: "南方文交所",
    shenzhenWjs: "深圳文交所",
    identificationEvaluation: "鑒定與評估委員會",
    collectionEvaluation: "收藏評估委員會",
    groggery: "藝術銀行酒店", //艺术银行酒店
    club: "藝術銀行俱樂部", //艺术银行俱乐部
    arts: "藝術類",
    trainingSystem: "藝術金融培訓體系",
    professionalCommittee: "藝術金融專業委員會",
    hotelCategory: "酒店類",
    financeType: "金融類",
    museum: "全國畫廊/博物館",
    membershipService: "藝術金融會員服務",
    intro: "集團簡介", // 集团简介
    news: "集團新聞", // 集团新闻
    newsInfo: "新聞詳情", // 新闻详情
    announcement: "公示公告",
    industryDynamics: "行業動態",
    videoData: "視頻資料",
    documentData: "文件資料",
    mediaReport: "媒體報道", //媒體报道
    corporateCulture: "企業文化", // 企业文化
    organizationalStructure: "組織架構", // 组织架构
    aptitude: "榮譽資質" // 荣誉资质
  },
  home: {
    // 业务板块
    businessSegment: {
      title: "業務·板塊",
      pledge: "藝術品質押",
      pawn: "藝術品典當",
      trust: "藝術品信托",
      insurance: "藝術品保險",
      consult: "藝術品咨詢",
      lease: "藝術品租賃",
      securitization: "藝術證券化",
      equityTransaction: "藝術品產權交易" //艺术品产权交易
    },
    // 精选艺术
    art: {
      title: "精選·藝術"
    },
    // 艺术活动
    artActivity: {
      title: "藝術·活動",
      roadRejuvenation: "復興之路",
      socialActivity: "社會活動",
      autonomousActivity: "自主活動",
      internationalExchange: "國際交流"
    },
    // 新闻中心
    newsCenter: {
      title: "新聞中心",
      expertWork: "專家工作",
      projectApplication: "項目申報",
      projectSupervision: "項目監管",
      projectSupervisionArt: "藝術部分",
      projectSupervisionExpenditure: "經費部分",
      viewMore: "查看更多"
    },
    // 页尾
    foot: {
      //关于我们
      aboutUs: {
        title: "關於我們",
        corporateCulture: "企業文化", // 企业文化
        organizationalStructure: "組織架構", // 组织架构
        qualificationHonor: "資質榮譽", // 资质荣誉
        newsInformation: "新聞咨詢", // 新闻资讯
        websiteStatement: "網站申明", // 网站申明
        siteMap: "站點地圖" // 站点地图
      },
      //集团业务
      groupBusiness: {
        title: "集團業務",
        artPledge: "藝術品典押", // 艺术品典押
        artPawning: "藝術品典當", // 艺术品典当
        artTrust: "藝術品信托", // 艺术品信托
        artInsurance: "藝術品保險", // 艺术品保险
        artSecuritization: "藝術證券化", // 艺术证券化
        equityTransaction: "藝術產權交易" // 艺术产权交易
      },
      //投资者关系
      investorRelations: {
        title: "投資者關係", // 投资者关系
        informationDisclosure: "信息披露", // 信息披露
        performanceReport: "業績報告", // 业绩报告
        socialResponsibility: "社會責任", // 社会责任
        OverseasBranch: "境外分行", // 境外分行
        clause: "條款細則", // 条款细则
        joinUs: "加入我們" // 加入我们
      },
      //联系我们
      contactUs: {
        title: "聯係我們",
        serviceHotline: "服務熱綫", // 服务热线
        suggestion: "投訴建議", // 投诉建议
        workingHours: "工作時間", // 工作时间
        postalCode: "郵政編碼", // 邮政编码
        companyAddress: "公司地址", // 公司地址
        companyAddressDes: "北京-朝陽-高碑店-國粹苑" // 公司详细地址
      }
    }
  },
  // 金融服务
  service: {},
  // 联系我们
  contact: {
    address: "地址",
    telephone: "電話",
    mail: "郵件",
    hotWire: "熱綫",
    beijing: "北京市豐臺區漢威國際廣場三區2號樓8M",
    shanghai: "中國上海市浦東新區銀城路116號",
    guangzhou: "廣東省廣州市番禺區興南大道32號1-2層",
    shenzhen: "中國廣東省深圳市福田區金田路2030號卓越世紀中心49樓",
    hongkong: "中國香港灣仔駱克道385-387號裕安商業大廈16樓",
    macau: "中國澳門大堂區新口岸冼星海大馬路澳門文化中心",
    newyork: "美國紐約林肯中心廣場NY 10023",
    sanFrancisco: "美國加利福尼亞州 舊金山，林肯公園 100號 34大街94121",
    singapore: "新加坡灣景道10號018956藝術科學博物館",
    tokyo: "日本東京都臺東區上野公園12-8號",
    suggestion1: "如果您有好的意見或建議；",
    suggestion2: "如果您對我們的工作尚有不滿意之處；",
    suggestion3: "如果您想對中國藝術銀行有更進壹步的了解……",
    suggestion4: "請與我們聯系，我們渴望與您交流，在交流中成長。"
  },
  common: {
    noContent: "暫無内容" // 暂无内容
  }
};
