export default {
  projectTitle: "China art bank",
  // 路由
  route: {
    home: "HOME", // 首页
    learnMore: "Details", // 首頁-詳情
    artist: "ARTIST", // 艺术家
    artisticActivity: "ACTIVITY", // 艺术活动
    artwork: "ARTWORK", // 艺术品
    financialService: "SERVICE", // 金融服务
    hotelClub: "CLUB", // 酒店俱乐部
    supportSystem: "SYSTEM", // 支撑体系
    tradingSystem: "Trading System",
    legalSystem: "Legal System",
    trainSystem: "Training System",
    serviceSystem: "Service System",
    marketingSystem: "Marketing System",
    financialSystem: "Financial System",
    supplyChainSystem: "Supply Chains",
    sortingSystem: "Clearing System",
    moisteningSystem: "Distribution",
    operationSystem: "Operation System",
    integratedSystem: "Association System",
    backdoorSystem: "Reverse Merger",
    incentiveSystem: "Excitation Mechanism",
    memberCenter: "MEMBER", // 会员中心
    contactUs: "CONTACT", // 联系我们
    newsCenter: "NEWS", // 新闻中心
    beijing: "Beijing",
    shanghai: "Shanghai",
    guangzhou: "Guangzhou",
    shenzhen: "Shenzhen",
    hongkong: "Hong Kong",
    macau: "Macau",
    newyork: "New York",
    sanFrancisco: "San Francisco",
    singapore: "Singapore",
    tokyo: "Tokyo",
    create: "Create",
    behavior: "Behavior",
    collect: "Collect",
    oilPainting: "Gouache for painting",
    ly1: "Liu Yuyi",
    inkCalligraphy: "Ink calligraphy",
    sketchPrint: "Sketch print",
    holding: "Works in the collection",
    music: "Music",
    popMusic: "Pop music",
    jazzMusic: "Jazz music",
    classicalMusic: "Classical music",
    dance: "Dance",
    classicalDance: "Classical dance",
    folkDance: "Folk dance",
    modernDance: "Modern dance",
    drama: "Drama",
    opera: "Opera",
    danceDrama: "Dance drama",
    puppetShow: "Puppet show",
    shadowPlay: "Shadow play",
    quyi: "quyi",
    brand: "Card music category",
    balladCi: "Ballad ci",
    drumsMelodies: "Drums and melodies",
    luteCalligraphy: "Lute and calligraphy",
    dogen: "Dogen",
    personage: "Personal collection",
    folk: "Folk collection",
    theme: "Subject collection",
    artGallery: "Art museum collection",
    insurance: "Insurance",
    pingan: "Ping An Insurance",
    taiping: "Taiping Insurance",
    lease: "Financial lease",
    factor: "Financial factoring",
    service: "Financial service",
    artPackage: "Artistic assets",
    social: "Social activity", // 社会活动
    autonomous: "Autonomic activities", // 自主活动
    internationalExchange: "International exchange", // 国际交流
    exhibitionTour: "Exhibition tour", //参展巡展
    office: "Take up a teaching post", //任职任教
    majorTheme: "Major theme", //重大题材
    acceptStudents: "Accept students", //收徒
    exhibition: "Hold an exhibition", //办展
    gift: "Present (a gift)", //馈赠
    associationPen: "International Association of PEN", //笔会
    internationalExhibitionTour: "International exhibition tour", //国际巡展
    beltRoad: "The Belt and Road", //一带一路
    stateAuction: "State auction", //国家拍卖
    importantCollection: "Important collection", //重要收藏
    internationalLecture: "International lecture", //国际讲学
    collectible: "Collections", //收藏品
    sort: "Sort", //分类
    all: "All", //全部
    calligraphyInscriptions: "Calligraphy inscriptions", //书法题字
    chinese: "Chinese ink painting", //水墨国画
    figureOil: "Figure oil painting", //人物油画
    stoneRhyme: "Stone rhyme painting", //石韵画
    world: "World series", //世界系列
    author: "Author", //作者
    dimension: "dimension", //尺寸
    price: "Price", //价格
    appraisalCenter: "Identification Center", // 鉴定中心
    wenChiao: "Cultural trade", // 文交系统
    south: "SCEE (South Cultural Equity Exchage)", //南方文交所
    shenzhenWjs: "SZCAEE", //深圳文交所
    identificationEvaluation: "Appraisal and Evaluation Committee", //鑒定與評估委員會
    collectionEvaluation: "Collection appraisal committee", //收藏評估委員會
    groggery: "Groggery", //艺术银行酒店
    club: "Club", //艺术银行俱乐部
    arts: "Arts",
    trainingSystem: "Art finance training system",
    professionalCommittee: "Arts Finance Professional Committee",
    hotelCategory: "Hotel category",
    financeType: "Finance type",
    museum: "National Gallery/Museum",
    membershipService: "Art Finance Membership service",
    intro: "Intro", // 集团简介
    news: "News", // 集团新闻
    newsInfo: "News details", // 新闻详情
    announcement: "Public announcement", // 公示公告
    industryDynamics: "Industry dynamics", // 行業動態
    videoData: "Video data", //視頻資料
    documentData: "Document data", //文件資料
    mediaReport: "Media report", //媒體报道,
    corporateCulture: "Corporate culture", // 企业文化
    organizationalStructure: "Organizational structure", // 组织架构
    aptitude: "Honor and qualification" // 荣誉资质
  },
  home: {
    // 业务模块
    businessSegment: {
      title: "BUSINESS·PLATE", //业务·板块
      pledge: "Art Pledge", //艺术品质押
      pawn: "Art Pawning", //艺术品典当
      trust: "Art Trust", //艺术品信托
      insurance: "Art Insurance", //艺术品保险
      consult: "Art Consultation", //艺术品咨询
      lease: "Art Rental", //艺术品租赁
      securitization: "Art Securitization", //艺术证券化
      equityTransaction: "Equity Transaction" //艺术品产权交易
    },
    // 精选艺术
    art: {
      title: "ART·SELECTION" //精选·艺术
    },
    // 艺术活动
    artActivity: {
      title: "ART·ACTIVITY",
      roadRejuvenation: "复兴之路",
      socialActivity: "社会活动",
      autonomousActivity: "自主活动",
      internationalExchange: "国际交流"
    },
    // 新闻中心
    newsCenter: {
      title: "News", // 新闻中心
      expertWork: "Expert Working Groups", // 专家工作
      projectApplication: "Project Application", // 项目申报
      projectSupervision: "Project Supervision", // 项目监管
      projectSupervisionArt: "Art Section", // 艺术部分
      projectSupervisionExpenditure: "Appropriation Portion", // 经费部分
      viewMore: "View more"
    },
    // 页尾
    foot: {
      //关于我们
      aboutUs: {
        title: "About Us",
        corporateCulture: "Corporate Culture", // 企业文化
        organizationalStructure: "Organizational Structure", // 组织架构
        qualificationHonor: "Qualification Honor", // 资质荣誉
        newsInformation: "News Information", // 新闻资讯
        websiteStatement: "Website Statement", // 网站申明
        siteMap: "Site Map" // 站点地图
      },
      //集团业务
      groupBusiness: {
        title: "Group Business",
        artPledge: "Art Pledge", // 艺术品典押
        artPawning: "Art Pawning", // 艺术品典当
        artTrust: "Art Trust", // 艺术品信托
        artInsurance: "Art Insurance", // 艺术品保险
        artSecuritization: "Art Securitization", // 艺术证券化
        equityTransaction: "Equity Transaction" // 艺术产权交易
      },
      //投资者关系
      investorRelations: {
        title: "Investor Relations",
        informationDisclosure: "Information Disclosure", // 信息披露
        performanceReport: "Performance Report", // 业绩报告
        socialResponsibility: "Social Responsibility", // 社会责任
        OverseasBranch: "Overseas Branch", // 境外分行
        clause: "Clause", // 条款细则
        joinUs: "Join Us" // 加入我们
      },
      //联系我们
      contactUs: {
        title: "Contact Us",
        serviceHotline: "Service Hotline", // 服务热线
        suggestion: "Integrity Report", // 投诉建议
        workingHours: "Working Hours", // 工作时间
        postalCode: "Postal Code", // 邮政编码
        companyAddress: "Company Address", // 公司地址
        companyAddressDes:
          "Beijing - Chaoyang - Gaobeidian - Quintessence Garden" // 公司详细地址
      }
    }
  },
  // 金融服务
  service: {},
  // 联系我们
  contact: {
    address: "Address",
    telephone: "Telephone",
    mail: "Mail",
    hotWire: "Hot Wire",
    beijing:
      "8M, Building 2, District 3, Hanwei International Plaza, Fengtai District, Beijing",
    shanghai: "116 Yincheng Road, Pudong New Area, Shanghai, China",
    guangzhou:
      "1-2 / F, 32 Xingnan Avenue, Panyu District, Guangzhou City, Guangdong Province",
    shenzhen:
      "49 / F, Century of Excellence Center, 2030 Jintian Road, Futian District, Shenzhen, Guangdong, China",
    hongkong:
      "16 / F, Yue On Commercial Building, 385-387 Lockhart Road, Wanchai, Hong Kong, China",
    macau:
      "Macau Cultural Center, Xian Xinghai Road, New Port, Lobby District, Macau, China",
    newyork: "Lincoln Center Plaza, New York, NY 10023, USA",
    sanFrancisco: "34th Street, 100 Lincoln Park, San Francisco, CA",
    singapore:
      "ArtScience Museum Singapore,018956 ,10 Bay View Road, Singapore",
    tokyo: "12-8 Uenokoen, Taito City, Tokyo 110-0007, Japan",
    suggestion1: "If You Have Good Comments or Suggestions；",
    suggestion2: "If You Are Atill Not Satisfied With Our Work；",
    suggestion3: "If You Want To Know More About The Art Bank of China……",
    suggestion4:
      "Please Contact Us, We Are Eager To Communicate With You, In The Communication To Grow。"
  },
  common: {
    noContent: "No content" // 暂无内容
  }
};
